import React, { useEffect, useState, useRef } from 'react';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { useInput } from 'rooks';
import { decode } from 'js-base64';
import { scroller, ScrollElement } from 'react-scroll';
import ContentLoader from 'react-content-loader';

import DeliveryBar from 'components/delivery-bar';
import NewAddress from 'components/new-address';
import GoogleMapFrame from 'components/google-map';

import Cancel from 'images/icons/i-cancel.svg';
import CircleCancel from 'images/icons/i-cross-circle-gray.svg';
import AngleArrorLeft from 'images/icons/angle-arrow-left-black.svg';
import Check from 'images/icons/round-check-fill.svg';
import twentyFourHourIcon from 'images/icons/ic-24h.svg';
import RoundCheck from 'images/icons/round-fill.svg';
import Edit from 'images/icons/i-edit.svg';
import imageLocationPoint from 'images/icons/i-location-point.svg';

import { useAPI } from 'utils/api';
import { STORE_TYPE } from 'utils/structure';
import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { dismissLoading, showLoading } from 'utils/loading';
import { setLocalStorage, getLocalStorage } from 'utils/localstorage';
import { useDialog } from 'utils/dialog';
import { setShippingAddress } from 'utils/_ga';
import { cityList } from 'utils/cityList';

// 1. 查找地址字串中有使用到的縣市。
// 2. 如果沒有輸入縣市，則回傳原字串，否則繼續進行下一步。
// 3. 查找使用到的該縣市名稱在地址中的 index，如果該縣市名稱是三個字，就直接查找 index。如果該縣市名稱是兩個字，會先排除掉該名稱三個字後再查找
const addressFilter = (address) => {
  const str = address.replace(/ /g, '');
  const cityListTwoStr = [...new Set(cityList.map((s) => s.slice(0, 2)))]; // ['台北', '台中', '台南', ...]
  const cityListAll = [...cityList, ...cityListTwoStr]; // ['台北市', '台中市', '台南市', '台北', '台中', '台南' ...]
  const cityIncluded = cityListAll.filter((cityStr) => str.includes(cityStr));

  if (cityIncluded.length > 0) {
    const strSorted = cityIncluded
      .map((s) => {
        if (s.length === 3) {
          return {
            city: s,
            index: str.indexOf(s),
          };
        }
        if (s.length === 2) {
          let idx = str.indexOf(s);
          while ([`${s}市`, `${s}縣`].includes(str.slice(idx, idx + 3))) {
            if (str.indexOf(s, idx + 3) === -1) {
              break;
            } else {
              idx = str.indexOf(s, idx + 3);
            }
          }
          return {
            city: s,
            index: idx,
          };
        }
      })
      .sort((a, b) => {
        return a.index - b.index;
      });

    const subStr = str.slice(strSorted[0].index + strSorted[0].city.length);
    let strFiltered = subStr;

    cityIncluded.forEach((s) => {
      if (s.length === 3) {
        while (strFiltered.includes(s)) {
          strFiltered = strFiltered.replace(s, '');
        }
      }

      // if (s.length === 2) {
      //   const extra = [`${s}路`, `${s}街`];
      //   const idx = strFiltered.indexOf(s);
      //   const isMatch = extra.includes(strFiltered.slice(idx, idx + 3));

      //   if (!isMatch && idx != -1) {
      //     strFiltered = `${strFiltered.slice(0, idx)}${strFiltered.slice(
      //       idx + 2,
      //       strFiltered.length
      //     )}`;
      //   }
      // }
      return;
    });
    return `${str.slice(
      0,
      strSorted[0].index + strSorted[0].city.length
    )}${strFiltered}`;
  }
  return str;
};

const IN_APP = process.env.GATSBY_INAPP === 'true';
const StoreRadioGroup = ({
  data,
  onChangeStore,
  selectStore,
  shippingModel,
}) => {
  return (
    <>
      <div
        className="store-radio-group"
        id="store-radio-group"
        name="store-radio-group"
      >
        {data.map((item, index) => (
          // <StoreRadio
          <StoreRadioElement
            key={index}
            data={item}
            onChangeStore={onChangeStore}
            selectStore={selectStore}
            shippingModel={shippingModel}
          />
        ))}
      </div>
      <style jsx>{`
        .store-radio-group {
          display: flex;
          gap: 11px;
          padding-left: 11px;
          overflow-x: auto;
          padding-bottom: 8px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
const StoreRadio = ({ data, onChangeStore, selectStore, shippingModel }) => {
  return (
    <>
      <label
        key={data.id}
        className="store-radio"
        htmlFor={data.id}
        id={`store-item-${data.id}`}
        name={`store-item-${data.id}`}
        onClick={() => handleStoreRadioScrollTo(data.id)}
      >
        <input
          className="store-input"
          type="radio"
          id={data.id}
          name="store"
          onChange={onChangeStore}
          checked={selectStore === data.id}
        />
        <div className="check-input">
          <img src={Check} alt="checked" className="checked" />
        </div>
        <p className="store-name">{data.name}</p>
        {data['24h'] ? (
          <img
            className="hr-icon"
            src={twentyFourHourIcon}
            alt="twentyFourHourIcon"
          />
        ) : (
          <></>
        )}
        <p className="store-type">{STORE_TYPE[data.c_sst_type]}</p>

        {shippingModel === '1' ? (
          <p className="store-info">外送費${data.shipmentCost}</p>
        ) : undefined}
        {/* <p className="store-info">{data.distance}公里</p> */}
        <p className="store-info">{data.estimationTime}</p>
      </label>
      <style jsx>{`
        .store-radio {
          position: relative;
          display: inline-block;
          width: 113px;
          padding: 10px 9px 5px 7px;
          border-radius: 16px;
          background-color: #fff;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
          flex-shrink: 0;
        }
        .hr-icon {
          margin-right: 5px;
        }
        .store-type {
          display: inline-block;
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
        .store-input {
          display: none;
        }
        .check-input {
          position: absolute;
          right: 9px;
          top: 9px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #e3e3e3;
        }
        .checked {
          display: none;
        }
        .store-input:checked ~ .check-input {
          width: 14px;
          height: 14px;
          border: none;
          .checked {
            display: block;
          }
        }
        .store-top {
        }
        .store-name {
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 3px;
        }
        .store-info {
          color: #999;
          font-size: 12px;
          line-height: 17px;
        }
      `}</style>
    </>
  );
};

const StoreRadioElement = ScrollElement(StoreRadio);

const handleStoreRadioScrollTo = (id) => {
  scroller.scrollTo(`store-item-${id}`, {
    duration: 1000,
    delay: 100,
    smooth: true,
    containerId: 'store-radio-group',
    // offset: -window.innerWidth / 2 + element.offsetWidth / 2,
    offset: 0,
    horizontal: true,
  });
};

const LocationRadio = ({
  data,
  setIsShow,
  setSelectData,
  setSelectEditData,
  setAnyAction,
  currentSelectData,
  currentInputAddress,
}) => {
  const [isCheck, setIsCheck] = useState(false);
  const onClickEdit = (event) => {
    event.preventDefault();
    setAnyAction(true);
    setIsShow(true);
    if (
      setSelectEditData !== undefined &&
      typeof setSelectData === 'function'
    ) {
      setSelectEditData(data);
    }
  };
  const onChange = (event) => {
    if (setSelectData !== undefined && typeof setSelectData === 'function') {
      setAnyAction(true);
      setSelectData(data);
    }
  };

  useEffect(() => {
    if (
      currentSelectData !== undefined &&
      currentSelectData.addressId === data.addressId &&
      currentInputAddress === addressFilter(data.address)
    ) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  }, [currentSelectData, data.addressId, currentInputAddress]);

  return (
    <>
      <label className="location-radio" htmlFor={data.addressId}>
        <div>
          <input
            className="location-input"
            type="radio"
            id={data.addressId}
            name="location"
            onChange={onChange}
            checked={isCheck}
          />
          <div className="check-input">
            <img src={RoundCheck} alt="checked" className="checked" />
          </div>
        </div>
        <div className="text">
          <div className="title">{data.tag}</div>
          <div className="address">{addressFilter(data.address)}</div>
        </div>
        <div className="edit" onClick={onClickEdit}>
          <img src={Edit} alt="Edit" className="edit" />
        </div>
      </label>
      <style jsx>{`
        .location-radio {
          position: relative;
          display: flex;
          padding-bottom: 12.5px;
          border-bottom: 1px solid #e4e4e4;
          align-items: center;
          justify-content: space-between;
          & ~ & {
            padding-top: 16.5px;
          }
          .text {
            flex-grow: 1;
            padding-left: 8px;
          }
          .edit {
            align-self: baseline;
          }
        }
        .location-input {
          display: none;
        }
        .check-input {
          width: 14px;
          height: 14px;
          background-color: #fff;
          border-radius: 100%;
          border: 1px solid #e3e3e3;
        }
        .checked {
          display: none;
        }
        .location-input:checked ~ .check-input {
          width: 16px;
          height: 16px;
          border: none;
          .checked {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

const AddressBlock = ({
  data,
  addressData,
  setAddressData,
  getStore,
  setAnyAction,
  setStoreData,
  callbackChangeAddress = () => {},
  removeAddress = () => {},
  setUpdateAddressBlock = () => {},
  showSave = () => {},
  setSelectAddressData,
}) => {
  const addressInput = useInput(addressData);
  const tmpAddress = useRef(addressData);

  const [updateAddress, setUpdateAddress] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  var blurTimeout = null;

  const search = () => {
    showSave(true);
    blurTimeout = setTimeout(() => {
      if (tmpAddress.current === addressInput.value) return;
      const address = addressFilter(addressInput.value);
      tmpAddress.current = address;
      setAddressData('');
      setAddressData(address);
      getStore(null, null, address);
      callbackChangeAddress(address);
    }, 25);
  };

  useEffect(() => {
    tmpAddress.current = addressData;
  }, [addressData]);

  return (
    <>
      <div className="address-bar">
        <div className="address-block">
          <div className="address-input-frame">
            {/* <img src={imageLocationPoint} /> */}
            <div className="address">
              <input
                key={updateAddress}
                type="text"
                className="address-input"
                placeholder="請輸入地址"
                {...addressInput}
                onBlur={search}
                onKeyDown={handleKeyDown}
                onClick={() => setAnyAction(true)}
                onFocus={() => showSave(false)}
                enterKeyHint="done"
              />
            </div>
            {/* ignore feature */}
            {addressInput.value !== '' ? (
              <img
                src={CircleCancel}
                className="remove_address"
                onClick={() => {
                  clearTimeout(blurTimeout);
                  setAddressData('');
                  setStoreData({});
                  setSelectAddressData({});
                  setUpdateAddressBlock();
                }}
              />
            ) : undefined}
          </div>
          <div className="store">{data.store}</div>
        </div>
      </div>
      <style jsx>{`
        .address-bar {
          width: 100%;
          box-sizing: border-box;
          position: absolute;
          top: 6px;
          padding: 0 16.65px 0 23.35px;
        }
        .address-block {
          height: auto;
          background: #ffffff;
          border: 1px solid #5fd2da;
          box-sizing: border-box;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
          padding: 8px 12.5px 8px 5.15px;
          border-radius: 8px;
          display: grid;
          // justify-content: space-between;
          // align-items: center;
          .address-input-frame {
            padding-bottom: 4px;
            display: flex;
            align-items: center;
            .address {
              flex-grow: 1;
              // margin: 0 1.6vw;
              padding: 0 5px;
              color: #333333;
              .address-input {
                padding: 0;
                width: 100%;
                background: transparent;
                border: 0;
                outline: none;
              }
            }
            .remove_address {
              height: 14px;
              margin-right: 4px;
            }
          }
          .store {
            font-weight: bold;
            padding: 0 5px;
            // margin: 0 2vw;
            color: #333333;
          }
        }
      `}</style>
    </>
  );
};

// const StoreRadioPreview = () => {
//   const viewWidth = document.body.clientWidth;
//   const mainWidth = 390;
//   const ratio = viewWidth / mainWidth;
//   return (
//     <>
//       <div className="store-radio-group">
//         <ContentLoader
//           speed={2}
//           width={113}
//           height={45}
//           viewBox={`0 0 ${113 * ratio} 45`}
//           // viewBox={`0 0 113 45`}
//           backgroundColor="#f3f3f3"
//           foregroundColor="#ecebeb"
//         >
//           {[...Array(5)].map((i, index) => {
//             return (
//               <>
//                 <rect x="0" y="0" rx="16" ry="16" width="113" height="45" />
//               </>
//             );
//           })}
//         </ContentLoader>
//       </div>

//       <style jsx>{`
//         .store-radio-group {
//           display: flex;
//           gap: 11px;
//           padding-left: 11px;
//           overflow-x: auto;
//           padding-bottom: 8px;
//           &::-webkit-scrollbar {
//             display: none;
//           }
//         }
//       `}</style>
//     </>
//   );
// };
const StoreRadioPreview = () => {
  const viewWidth = document.body.clientWidth;
  const mainWidth = 390;
  const ratio = viewWidth / mainWidth;
  console.log('ratio: ' + ratio);
  return (
    <>
      <div className="store-radio-group">
        <ContentLoader
          width={129 * 5 * ratio} // 計算矩形和間距的總寬度
          height="101" // 修改矩形的高度
          viewBox={`0 0 ${390 * ratio} 101`} // 計算總視窗寬度
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          {[...Array(5)].map((i, index) => {
            return (
              <>
                <rect
                  key={index}
                  x={index * (129 + 11) * ratio} // 計算每個矩形的位置
                  y="0"
                  rx="16"
                  ry="16"
                  width={129 * ratio}
                  height="101" // 修改矩形的高度
                />
              </>
            );
          })}
        </ContentLoader>
      </div>

      <style jsx>{`
        .store-radio-group {
          display: flex;
          gap: 11px;
          padding-left: 11px;
          overflow-x: auto;
          padding-bottom: 8px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

const DispatchSetting = () => {
  const api = useAPI();
  const dialog = useDialog();

  // near by stores
  const nearByStores = useSelector((state) => getters.getNearByStores(state));
  // address data
  const address = useSelector((state) => getters.getAddress(state));
  // store timeslots
  const storeTimeSlots = useSelector((state) =>
    getters.getStoreTimeSlots(state)
  );

  const hashAccount = useSelector((state) => getters.getHashAccount(state));

  // select address
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [selectAdderssData, setSelectAddressData] = useState({});
  const [selectEditData, setSelectEditData] = useState({});

  const [currentAddress, setCurrentAddress] = useState('');
  const [updateAddressField, setUpdateAddressField] = useState(false);

  const [newAddressKey, setNewAddressKey] = useState(false);

  const [anyAction, setAnyAction] = useState(false);

  // store select
  const [selectStore, setSelectStore] = useState();

  // history addresses
  const [historyAddresses, setHistoryAddresses] = useState([]);

  const [selectTimeslot, setSelectTimeslot] = useState({});
  const [selectTimeslotIndex, setSelectTimeslotIndex] = useState(0);

  const [showSave, setShowSave] = useState(true);

  // post data
  // const [postData, setPostData] = useState({});
  const postData = useRef({});

  // get history addresses
  const getHistoryAddresses = () => {
    const historyAddressKey = 'historyAddressKey';
    // check localstorage
    var historyData = getLocalStorage(historyAddressKey);
    if (historyData === null || historyData === undefined) {
      historyData = [];
    }
    setHistoryAddresses(historyData.reverse());
  };

  const onChangeStore = (event) => {
    // event.preventDefault();
    setSelectStore(event.currentTarget.id);
    setAnyAction(true);

    const findObj = nearByStores.find((store) => {
      return store.id === event.currentTarget.id;
    });

    setStoreData({
      address: findObj.address,
      store: findObj.name,
    });
  };

  // location
  const [location, setLocation] = useState({ lat: 25.04, lng: 121.51 });
  const zoom = 16;

  // store information
  const [storeData, setStoreData] = useState({
    address: '',
    store: '',
  });

  const checkSelectAddress = (addressString) => {
    const result = address.find((item) => {
      return item.address === addressString;
    });

    if (result === undefined || result === null) {
      setSelectAddressData({});
    } else {
      setSelectAddressData(result);
    }
  };

  const phoneFormatValid = (phone) => {
    if (phone === undefined || phone === '') {
      return false;
    }

    var mobileRegex = /^09\d{8}$/;

    return mobileRegex.test(phone);
  };

  const onClickUpdateAddress = (postData) => {
    if (!postData.fullName) {
      alert('請填寫姓名');
      return;
    }
    if (!postData.phone) {
      alert('請填寫手機號碼');
      return;
    }
    if (!phoneFormatValid(postData.phone)) {
      alert('手機號碼格式錯誤');
      return;
    }
    showLoading();
    api
      .updateAddress(postData)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }
        setIsShowEdit(false);
        getAddress();
      })
      .catch((error) => {
        dismissLoading();
        alert(error);
      });
  };

  const getStore = (lat, lng, addr) => {
    // 清空門店、選擇門店資料
    store.dispatch(actions.setNearByStores([]));
    setSelectStore();
    setStoreData({});

    if (lat === null && lng === null && addr === '') return;

    var shippingModel = '1';
    if (IN_APP) {
      shippingModel = postData.current.shippingModel ?? '1';
    }
    api.getStoreByLocation(lat, lng, addr, shippingModel).then((res) => {
      if (!res) {
        setCurrentAddress('');
        return;
      }

      if (res.rows.length === 0) {
        alert('無法配送');
      }

      //判斷預設門店有無在當下地址附近
      const result = nearByStores.find(
        (store, index) => store.id === postData.current.storeId
      );
      // set default select 如果有預設資料不要抓到
      if (res.rows.length > 0) {
        setSelectStore(res.rows[0].id);
        setStoreData({
          address: res.rows[0].address,
          store: res.rows[0].name,
        });
        handleStoreRadioScrollTo(res.rows[0].id);
      }

      if (addr != null && addr !== undefined) {
        setLocation({
          lat: res.query.latitude,
          lng: res.query.longitude,
        });
      } else {
        // setCurrentAddress(`${res.query.postalCode}${res.query.address}`);
        setCurrentAddress(`${res.query.address}`);
      }
      store.dispatch(actions.setNearByStores(res.rows));
    });
  };

  const getAddress = () => {
    showLoading();
    api
      .getAddress()
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }
        store.dispatch(actions.setAddress(res.rows));

        // 判斷是否需要使用預設地址
        // 地址爲空，使用預設
        if (
          (IN_APP &&
            (postData.current.receiverAddress === undefined ||
              postData.current.receiverAddress === null ||
              postData.current.receiverAddress === '')) ||
          (!IN_APP &&
            (currentAddress === undefined ||
              currentAddress === null ||
              currentAddress === ''))
        ) {
          const result = res.rows.find((addr, index) => addr.isDefault);

          setSelectAddressData(result);
        }
      })
      .catch((err) => {
        dismissLoading();
      });
  };

  const getTimeSlots = () => {
    api
      .getStoreTimeSlots(selectStore)
      .then((res) => {
        if (!res) {
          setSelectTimeslot({});
          return;
        }
        store.dispatch(actions.setStoreTimeSlots(res));
        if (res.length > 0) {
          setSelectTimeslot(res[0]);
        } else {
          setSelectTimeslot({});
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setNewAddressKey(!newAddressKey);
  }, [isShowEdit]);

  useEffect(() => {
    if (
      selectAdderssData !== undefined &&
      typeof selectAdderssData === 'object' &&
      Object.keys(selectAdderssData).length > 0
    ) {
      const fullAddress = addressFilter(selectAdderssData.address);
      setCurrentAddress(fullAddress);
      getStore(null, null, fullAddress);
    }
  }, [selectAdderssData]);

  useEffect(() => {
    setSelectTimeslotIndex();
    setTimeout(() => {
      setSelectTimeslotIndex(0);
    }, 500);
    getTimeSlots();
  }, [selectStore]);

  useEffect(() => {
    if (!IN_APP) {
      // get location data
      getStore();
      // get save addresses
      getAddress();
      // get history addresses
      getHistoryAddresses();

      return;
    }

    WebFn({ callback: jsBridgeCallback });
    callAppFunc('fetchDispatchData', {});
  }, []);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      const elevator = postData.current.receiverElevator === '1' ? true : false;
      const result = address.find(
        (addr, index) => addr.addressId === postData.current.addressId
      );
      if (result !== undefined && result != null) {
        setSelectAddressData(result);
      }
    }
  }, [address]);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      var findIndex = 0;
      var findData = false;

      const result = storeTimeSlots.find((slot, index) => {
        if (
          selectStore === postData.current.storeId &&
          slot.shippingDate === postData.current.shippingDate &&
          slot.fastDeliveryPeriod === postData.current.fastDeliveryPeriod &&
          slot.shippingPeriod === postData.current.shippingPeriod &&
          slot.shippingTimeslot === postData.current.shippingTimeslot
        ) {
          findIndex = index;
          return true;
        }
      });
      if (result !== undefined && result != null) {
        setSelectTimeslot(result);
        setSelectTimeslotIndex(findIndex);
        findData = true;
      } else {
        if (
          postData.current.shippingPeriod === '-1' &&
          postData.current.fastDeliveryPeriod === ''
        ) {
          const resultEnd = storeTimeSlots.find((slot, index) => {
            if (slot.shippingPeriod === '-1') {
              findIndex = index;
              return true;
            }
          });

          if (resultEnd !== undefined && resultEnd !== null) {
            setSelectTimeslot(resultEnd);
            setSelectTimeslotIndex(findIndex);
            findData = true;
          }
        }
      }
      if (!findData && storeTimeSlots.length > 0) {
        setSelectTimeslot(storeTimeSlots[0]);
        setSelectTimeslotIndex(0);
        findData = true;
      }
    }
  }, [storeTimeSlots]);

  useEffect(() => {
    if (
      typeof postData.current === 'object' &&
      Object.keys(postData.current).length > 0
    ) {
      // 表示有預設資料
      const result = nearByStores.find(
        (store, index) => store.id === postData.current.storeId
      );
      if (result !== undefined && result != null) {
        setSelectStore(result.id);
        setStoreData({
          address: result.address,
          store: result.name,
        });
        handleStoreRadioScrollTo(result.id);
      }
    }
  }, [nearByStores]);

  const jsBridgeCallback = (strJson) => {
    const dataJson = JSON.parse(strJson);
    if (dataJson.fnName === 'sendDispatchData') {
      const data = JSON.parse(decode(dataJson.params.data));
      handleAppData(data);
      return;
    }
  };

  const handleAppData = (data = postData.current) => {
    postData.current = data;
    if (
      postData.current.receiverAddress != null &&
      postData.current.receiverAddress !== undefined &&
      postData.current.receiverAddress !== ''
    ) {
      setCurrentAddress(postData.current.receiverAddress);
      setUpdateAddressField(!updateAddressField);
      getStore(null, null, postData.current.receiverAddress);
    }

    // get save addresses
    getAddress();
    // get history addresses
    getHistoryAddresses();
  };

  const addHistoryAddressLocalStorage = (addr) => {
    if (addr === undefined || addr == null || addr === '') {
      return;
    }
    const historyAddressKey = 'historyAddressKey';
    // check localstorage
    let historyData = getLocalStorage(historyAddressKey);
    if (historyData === null || historyData === undefined) {
      historyData = [];
    }

    const index = historyData.indexOf(addr);
    if (index > -1) {
      historyData.splice(index, 1);
    }

    historyData.push(addr);

    if (historyData.length > 3) {
      historyData.splice(0, 1);
    }
    setLocalStorage(historyAddressKey, historyData, 315_360_000);
  };

  const onSelectSaveAddress = (event) => {
    setAnyAction(true);
    setCurrentAddress(event.target.dataset.address);
    getStore(null, null, event.target.dataset.address);

    const result = address.find((item) => {
      return item.address === event.target.dataset.address;
    });

    if (result === undefined || result === null) {
      setSelectAddressData({});
    } else {
      setSelectAddressData(result);
    }
  };

  const updateLocation = (mLocation) => {
    setAnyAction(true);
    setLocation(mLocation);
    getStore(mLocation.lat, mLocation.lng);
  };

  const saveData = () => {
    if (
      currentAddress === undefined ||
      currentAddress == null ||
      currentAddress === '' ||
      selectStore === undefined ||
      selectStore == null ||
      selectStore === ''
    ) {
      alert('還沒有填寫/選擇配送地址');
      return;
    }

    if (!anyAction) {
      setShippingAddress(hashAccount);
    }

    const isFirstSelectStore =
      postData.current.storeId === undefined ||
      postData.current.storeId === null ||
      postData.current.storeId === '';

    const isChangeStore =
      selectStore !== postData.current.storeId &&
      postData.current.storeId !== undefined &&
      postData.current.storeId !== null &&
      postData.current.storeId !== '';

    if (isChangeStore || isFirstSelectStore) {
      if (isFirstSelectStore) {
        dispatchDataHandle();
        showLoading();
        api
          .changeStore({ storeId: selectStore })
          .then((res) => {
            dismissLoading();
            callAppFunc('saveDispatchData', {
              data: JSON.stringify(postData.current),
            });

            callAppFunc('dismissDispatchSetting', {});
            addHistoryAddressLocalStorage(currentAddress);
          })
          .catch((error) => {
            dismissLoading();
          });
      } else {
        confirm(
          '切換出貨地址或選擇其他門店，若商品庫存不足時，原購物車內商品將會清除',
          '更換門店',
          '取消',
          { title: { 'font-size': '23px' } }
        ).then(() => {
          dispatchDataHandle();
          showLoading();
          api
            .changeStore({ storeId: selectStore })
            .then((res) => {
              dismissLoading();
              callAppFunc('saveDispatchData', {
                data: JSON.stringify(postData.current),
              });

              callAppFunc('dismissDispatchSetting', {});
              addHistoryAddressLocalStorage(currentAddress);
            })
            .catch((error) => {
              dismissLoading();
            });
        });
      }
    } else {
      dispatchDataHandle();
      callAppFunc('saveDispatchData', {
        data: JSON.stringify(postData.current),
      });

      callAppFunc('dismissDispatchSetting', {});
      addHistoryAddressLocalStorage(currentAddress);
    }
  };

  const dispatchDataHandle = () => {
    postData.current = {
      ...postData.current,
      storeId: selectStore,
      storeName: storeData.store,
      storeAddress: storeData.address,
      shippingDate: selectTimeslot ? selectTimeslot.shippingDate ?? '' : '',
      shippingPeriod: selectTimeslot ? selectTimeslot.shippingPeriod ?? '' : '',
      shippingTimeslot: selectTimeslot
        ? selectTimeslot.shippingTimeslot ?? ''
        : '',
      fastDeliveryPeriod: selectTimeslot
        ? selectTimeslot.fastDeliveryPeriod ?? ''
        : '',
    };

    postData.current =
      typeof selectAdderssData === 'object' &&
      Object.keys(selectAdderssData).length > 0 &&
      selectAdderssData.address === currentAddress
        ? {
            ...postData.current,
            receiverName: selectAdderssData.fullName ?? '',
            receiverPhone: selectAdderssData.phone ?? '',
            receiverAddress: selectAdderssData.address ?? '',
            receiverElevator: selectAdderssData.deliveryIsElevator ? '1' : '0',
            receiverReceive: selectAdderssData.deliveryPointValue ?? '',
            receiverAddressRemark: selectAdderssData.deliveryNote ?? '',
            addressId: selectAdderssData.addressId ?? '',
          }
        : {
            ...postData.current,
            receiverAddress: currentAddress,
            receiverPhone: '',
            receiverName: '',
            addressId: '',
            receiverElevator: '',
            receiverReceive: '',
            receiverAddressRemark: '',
          };

    const tmpJsonString = localStorage.getItem('TmpCartData');
    if (tmpJsonString !== null) {
      var tmpJson = JSON.parse(tmpJsonString);
      tmpJson = {
        ...tmpJson,
        receiverAddress: currentAddress,
        storeName: storeData.store,
      };

      if (
        typeof selectAdderssData === 'object' &&
        Object.keys(selectAdderssData).length > 0 &&
        selectAdderssData.address === currentAddress
      ) {
        tmpJson = {
          ...tmpJson,
          receiverName: selectAdderssData.fullName ?? '',
          receiverPhone: selectAdderssData.phone ?? '',
          receiverAddress: selectAdderssData.address ?? '',
          receiverElevator: selectAdderssData.deliveryIsElevator ? '1' : '0',
          receiverReceive: selectAdderssData.deliveryPointValue ?? '',
          receiverAddressRemark: selectAdderssData.deliveryNote ?? '',
          addressId: selectAdderssData.addressId ?? '',
        };
      }

      localStorage.setItem('TmpCartData', JSON.stringify(tmpJson));
    } else {
      localStorage.setItem('TmpCartData', JSON.stringify(postData.current));
    }
  };

  const collectionData = () => {
    const parameters = {
      storeId: postData.current.storeId,
      shippingDate:
        selectTimeslot === undefined || selectTimeslot.shippingDate === ''
          ? postData.current.shippingDate
          : selectTimeslot.shippingDate,
      shippingPeriod:
        selectTimeslot === undefined || selectTimeslot.shippingPeriod === ''
          ? postData.current.shippingPeriod
          : selectTimeslot.shippingPeriod, //postData.current.shippingPeriod,
      shippingTimeslot:
        selectTimeslot === undefined || selectTimeslot.shippingTimeslot === ''
          ? postData.current.shippingTimeslot
          : selectTimeslot.shippingTimeslot,
      fastDeliveryPeriod:
        selectTimeslot === undefined || selectTimeslot.fastDeliveryPeriod === ''
          ? postData.current.fastDeliveryPeriod
          : selectTimeslot.fastDeliveryPeriod, //selectTimeslot.fastDeliveryPeriod,
      shippingModel: postData.current.shippingModel,
      receiverName: postData.current.receiverName,
      receiverPhone: postData.current.receiverPhone,
      receiverAddress: postData.current.receiverAddress,
      receiverZip: postData.current.recevierZip,
      receiverElevator: elevatorValue, //postData.current.receiverElevator,
      receiverReceive: receiveTypeValue, //postData.current.receiverReceive,
      receiverAddressRemark: noteDipatch,
    };

    return parameters;
  };

  return (
    <>
      <div className="page">
        <div className="header-bar">
          <img
            // src={AngleArrorLeft}
            src={Cancel}
            alt="cancel"
            className="header-cancel"
            onClick={() => {
              // saveData();
              if (
                postData.current.storeId === undefined ||
                postData.current.storeId === ''
              ) {
                alert('還沒有填寫/選擇配送地址');
                return;
              }
              callAppFunc('dismissDispatchSetting', {});
            }}
          />
        </div>
        <DeliveryBar
          defaultSelectIndex={selectTimeslotIndex}
          timeslots={storeTimeSlots}
          setSelectTimeSlots={setSelectTimeslot}
          setAnyAction={setAnyAction}
          shippingModel={IN_APP ? postData.current.shippingModel : '1'}
          getTimeSlots={getTimeSlots}
        />
        <div className="map-section">
          <div id="map">
            <GoogleMapFrame
              location={location}
              zoom={zoom}
              updateLocation={updateLocation}
            />
          </div>
          <AddressBlock
            key={updateAddressField}
            data={storeData}
            addressData={addressFilter(currentAddress)}
            setAddressData={setCurrentAddress}
            getStore={getStore}
            setAnyAction={setAnyAction}
            callbackChangeAddress={checkSelectAddress}
            setStoreData={setStoreData}
            setSelectAddressData={setSelectAddressData}
            setUpdateAddressBlock={() => {
              setUpdateAddressField(!updateAddressField);
            }}
            showSave={setShowSave}
          />
        </div>
        {/* <div className="store-preview">
          <StoreRadioPreview></StoreRadioPreview>
        </div> */}
        {nearByStores.length > 0 ? (
          <div>
            <div className="store-list">
              <StoreRadioGroup
                shippingModel={IN_APP ? postData.current.shippingModel : '1'}
                data={nearByStores}
                onChangeStore={onChangeStore}
                selectStore={selectStore}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="store-preview">
              <StoreRadioPreview></StoreRadioPreview>
            </div>
          </div>
        )}

        <div className="curr-locations">
          <h2>目前地點</h2>
          <div className="address">{currentAddress}</div>
        </div>
        <div className="saved-locations">
          <h2>已儲存地點</h2>
          <div className="location-list">
            {address.map((item, index) => (
              <LocationRadio
                key={index}
                data={item}
                setIsShow={setIsShowEdit}
                setSelectData={setSelectAddressData}
                setSelectEditData={setSelectEditData}
                setAnyAction={setAnyAction}
                currentSelectData={selectAdderssData}
                currentInputAddress={currentAddress}
              />
            ))}
          </div>
        </div>
        <div className="recent-locations">
          <h2>最近使用的地點</h2>
          <div className="recent-list">
            {historyAddresses.map((address, index) => (
              <div className="recent-location" key={index}>
                <div
                  className="text"
                  data-address={address}
                  onClick={onSelectSaveAddress}
                >
                  {address}
                </div>
              </div>
            ))}

            {/* <div className="edit">
                <img src={Edit} alt="Edit" className="edit" />
              </div> */}
          </div>
        </div>
        {/* {showSave ? ( */}
        {true ? (
          <div className="button-block">
            <div
              onClick={() => saveData()}
              className="button"
            >
              確認
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <NewAddress
        key={isShowEdit}
        isShow={isShowEdit}
        setIsShow={setIsShowEdit}
        data={selectEditData}
        clickUpdate={onClickUpdateAddress}
      />
      <style jsx>{`
        .page {
          position: relative;
        }
        .header-bar {
          height: 24px;
          width: 100%;
          padding: 14px 0 12px;
          position: relative;
        }
        .header-cancel {
          position: absolute;
          top: 14px;
          left: 23px;
          display: inline-block;
          // height: 24px;
          height: 17.56px;
          margin-right: 8px;
        }
        .map-section {
          position: relative;
        }
        #map {
          width: 100%;
          height: 204px;
          background-color: #aaa;
          position: relative;
        }
        .store-list {
          overflow-x: scroll;
          margin-top: -33px;
          padding-bottom: 30px;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .store-preview {
          overflow-x: scroll;
          margin-top: -33px;
          padding-bottom: 30px;
          &::-webkit-scrollbar {
            display: none;
          }
          transform: translateZ(1px);
        }
        .curr-locations {
          padding: 0 15.5px 24px 14px;
        }
        h2 {
          padding-bottom: 11px;
        }
        .saved-locations,
        .recent-locations {
          min-height: 44px;
          padding: 0 15.5px 24px 14.5px;
        }
        .recent-location {
          display: flex;
          justify-content: space-between;
          padding-top: 16.5px;
          padding-bottom: 12.5px;
          border-bottom: 1px solid #e4e4e4;
        }
        .button-block {
          position: sticky;
          bottom: 0;
          width: calc(100vw - 32px);
          height: 60px;
          padding: 20px 16px;
          background-color: #ffffff;
          box-shadow: 0px 10px 15px 10px #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
          .button {
            width: 100%;
            height: 60px;
            padding: 0 35px;
            background: #5fd2da;
            border: 1px solid #5fd2da;
            box-sizing: border-box;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            line-height: 29px;
            color: #ffffff;
          }
        }
      `}</style>
    </>
  );
};

export default DispatchSetting;
